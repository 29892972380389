/**
 * @generated SignedSource<<73392cc3d316ec80d9e303d3812e24af>>
 * @relayHash 7ab3e7770623089670b6171a81365649
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-trade/505.0.0-2025-04-09T15:00:40.730Z/useAddressAutocompleteQuery

import { ConcreteRequest } from 'relay-runtime';
export type useAddressAutocompleteQuery$variables = {
  addressInput: string;
  placeType?: string | null;
  sessionToken: string;
};
export type useAddressAutocompleteQuery$data = {
  readonly viewer: {
    readonly addressAutocomplete: ReadonlyArray<{
      readonly addressLine: string | null;
      readonly displayValue: string | null;
      readonly placeId: string | null;
    } | null> | null;
  };
};
export type useAddressAutocompleteQuery = {
  response: useAddressAutocompleteQuery$data;
  variables: useAddressAutocompleteQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "addressInput"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "placeType"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sessionToken"
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "addressInput",
      "variableName": "addressInput"
    },
    {
      "kind": "Variable",
      "name": "placeType",
      "variableName": "placeType"
    },
    {
      "kind": "Variable",
      "name": "sessionToken",
      "variableName": "sessionToken"
    }
  ],
  "concreteType": "AddressAutocompleteOption",
  "kind": "LinkedField",
  "name": "addressAutocomplete",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "placeId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayValue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "addressLine",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useAddressAutocompleteQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "useAddressAutocompleteQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-trade/505.0.0-2025-04-09T15:00:40.730Z/useAddressAutocompleteQuery",
    "metadata": {},
    "name": "useAddressAutocompleteQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "8e550d7c6caa12a7b129c855aec9243c";

export default node;
